import './Header.scss';
import { ReactComponent as ArrowIcon } from '../../assets/ArrowIcon.svg';
import { useScrollSection } from '../../hooks/ScrollSection/ScrollSection';

function Header() {
  const { setScrollHook } = useScrollSection();

  return (
    <header id='section-0' className='header'>
      <div className='headerContent'>
        <h1 className='mainTitle'>Il n’a jamais été aussi simple d’aller d'un point A à un point B en toute sécurité</h1>
        <img src='https://placehold.co/600' alt='Placeholder' />
      </div>
      <button onClick={() => setScrollHook(6, 'down')} className='arrowButton down'>
        <ArrowIcon />
      </button>
    </header>
  );
}

export default Header;
