import StickmanWalkRed from '../../assets/StickmanWalkRed.json'

const data = {
  color: '#FFFFFF',
  title: 'EFFRAYANT ?',
  subTitle: 'Marcher seul ne devrait pas l’être',
  scenario: [
    {
      animationData: StickmanWalkRed,
      timeStart: 3800,
      duration: 1000
    }
  ]
};

function Section1() {

  

  return (
    <section
      id="section-1"
      className='sectionApp'
      style={{ color: data.color }}
    >
      <h2 className='sectionTitle'>{data.title}</h2>
      <span className='subTitle'>{data.subTitle}</span>
    </section>
  )
}

export default Section1;
